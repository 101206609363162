import { axiosInstance } from "../../providers/api";

export interface Member {
  id: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  full_name: string;
  secondary_email?: string;
  phone_cellphone?: string;
  phone_landline?: string;
  subscription_ids: string[];
  subscription_status: "new" | "active" | "active_terminated" | "terminated";
  customer_id?: number;
  signup_at?: string;
  last_login_at?: string;
  onboarding_email_status: "sent" | "scheduled" | "not_scheduled";
  onboarding_email_status_at: string | null;
  can_be_deleted: boolean;
  can_be_cancelled: boolean;
  can_receive_onboarding_email: boolean;
  can_be_subscribed: boolean;
}

export interface Subscription {
  id: string;
  start_at: string;
  end_at: string;
  status: "pending" | "active" | "terminated" | "suspended" | "aborted";
  can_be_cancelled: boolean;
  can_be_edited: boolean;
  member_id: number;
  product_id?: number;
}

export type SendOnboardingEmailsParams = {
  id: number;
};

export const memberProviderMethods = {
  async cancelMember(memberId: number, cancelAt?: Date) {
    return axiosInstance.post(`/staff/members/${memberId}/cancel`, { cancel_at: cancelAt?.toISOString() });
  },
  async resetMemberPassword(memberId: number) {
    return axiosInstance.post(`/staff/members/${memberId}/reset-password`);
  },
  async sendOnboardingEmail(memberId: number): Promise<void> {
    await axiosInstance.post<void>(`/staff/members/${memberId}/send-onboarding-email`);
  },
  async subscribeMember(memberId: number, productId: number) {
    return axiosInstance.post(`/staff/members/${memberId}/subscribe`, { product_id: productId });
  },
  async anonymizeMemberData(memberId: number) {
    return axiosInstance.post(`/staff/members/${memberId}/anonymize-data`);
  },
};

export type MemberDataProvider = typeof memberProviderMethods;
