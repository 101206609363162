import fr from "ra-language-french";

export default {
  ...fr,
  "app.name": "Backoffice",
  "answer.label": "Réponse |||| Réponses",
  "aside.member.id.label": "Identifiant membre",
  "aside.member.email.label": "Email du membre",
  "aside.member.fullName.label": "Nom du membre",
  "aside.member.customer.name.label": "Client de rattachement",
  "aside.member.customer.product.label": "Produit",
  "aside.member.customer.product.black_list_tags.label": "Tags exclus par le produit",
  "aside.member.customer.product.white_list_tags.label": "Uniques tags autorisés par le produit",
  "aside.answers.title": "Réponses",
  "aside.assignations.title": "Assignations",
  "login.label": "Pour accéder au backoffice, connectez vous avec votre compte professionnel Ekie",
  "nav.staff.label": "Ekipe",
  "nav.lawyer.label": "Réseau d'avocats",
  "nav.members.label": "Membres",
  "nav.customers.label": "Clients",
  "nav.consultations.label": "Consultations",
  "component.dialog.action.button.label": "Confirmer",
  "component.dialog.action.error": "Impossible d'effectuer l'action",
  "consultation.edit.title": "Consultation %{consultationId}",
  "consultation.edit.ratingReason.title": "Raison",
  "consultation.edit.ratingReason.late": "En retard",
  "consultation.edit.ratingReason.no_show": "L'avocat n'est pas présent au RDV",
  "consultation.edit.ratingReason.unsatisfying_answers": "Réponses apportées insatisfaisantes",
  "consultation.edit.ratingReason.other": "Autre",
  "consultation.edit.consultation.title": "Consultation",
  "consultation.edit.cancel.dialog.title": "Annuler ?",
  "consultation.edit.cancel.button.label": "Annuler",
  "consultation.edit.cancel.reason.label": "Raison",
  "consultation.edit.cancel.to_reschedule.label": "Laisser la possibilité au membre de reprogrammer un RDV",
  "consultation.edit.complete.button.label": "Cloturer",
  "consultation.edit.complete.dialog.title": "Cloturer ?",
  "consultation.edit.complete.dialog.content":
    "En clôturant la consultation le rendez vous sera marqué comme étant effectué. Le membre sera notifié que sa consultation a bien eu lieu.",
  "consultation.edit.appointment.title": "Rendez-vous",
  "consultation.edit.appointment.status.pending": "Prévu",
  "consultation.edit.appointment.status.finalized": "Effectué",
  "consultation.edit.appointment.status.lawyer_unavailable": "Avocat non disponible",
  "consultation.edit.appointment.status.lawyer_recused": "Avocat s'est récusé",
  "consultation.edit.appointment.status.lawyer_noshow": "Avocat non présent au RDV",
  "consultation.edit.appointment.status.customer_unavailable": "Membre non disponible",
  "consultation.edit.appointment.status.customer_dispute": "Contestation du membre",
  "consultation.edit.appointment.status.customer_noshow": "Membre non présent au RDV",
  "consultation.edit.appointment.status.customer_order_cancellation": "Annulation abonnement membre",
  "consultation.edit.appointment.status.customer_move_cancellation": "RDV déplacé par le membre",
  "consultation.edit.appointment.status.assignment_error": "Problème d'attribution",
  "consultation.edit.appointment.status.other": "Annulation pour raison inconnue",
  "consultation.edit.appointment.column.startAt.title": "Date de début du RDV",
  "consultation.edit.appointment.column.status.title": "Statut",
  "consultation.aside.question.id.label": "Identifiant question",
  "consultation.aside.question.createdAt.label": "Date de création de la question",
  "consultation.aside.question.memberRating.label": "Note donnée par le membre à la réponse",
  "consultation.list.title": "Consultations",
  "consultation.list.filter.memberRating.label": "Note donnée",
  "consultation.list.column.id.title": "id",
  "consultation.list.column.createdAt.title": "Date de la demande de consultation",
  "consultation.list.column.member.title": "Membre",
  "consultation.list.column.domain.title": "Domaine",
  "consultation.list.column.lawyer.title": "Avocat",
  "consultation.list.column.status.title": "Statut",
  "consultation.list.column.memberRating.title": "Note donnée par le membre",
  "consultation.list.column.status.status_pending": "En attente de paiement",
  "consultation.list.column.status.status_completed": "Finalisé",
  "consultation.list.column.status.status_scheduled": "Prévu",
  "consultation.list.column.status.status_to_reschedule": "A reprogrammer",
  "consultation.list.column.status.status_cancelled": "Annulé",
  "consultation.list.column.appointmentScheduledStartAt.title": "Date prévue de la consultation",
  "customer.list.title": "Client |||| Clients",
  "customer.list.filter.search.label": "Nom ou code",
  "customer.list.filter.type.label": "Type",
  "customer.list.column.name.title": "Nom",
  "customer.list.column.code.title": "Code",
  "customer.list.column.type.title": "Type",
  "customer.list.column.type.distributor": "Distributeur",
  "customer.list.column.type.employer": "Employeur",
  "customer.show.title": "Informations client",
  "customer.edit.name.label": "Nom",
  "customer.edit.code.label": "Code client",
  "customer.edit.type.label": "Type de client",
  "customer.edit.section.product.title": "Produits",
  "customer.edit.section.product.column.name.title": "Nom",
  "customer.edit.section.product.column.collaborator_type.title": "Type de collaborateur",
  "customer.edit.section.product.column.collaborator_type.cse": "CSE",
  "customer.edit.section.product.column.collaborator_type.employee": "Employé",
  "customer.schedule_onboarding_emails.dialog.title": "Planifier l'envoi des emails de bienvenue",
  "customer.schedule_onboarding_emails.dialog.at": "Date et heure de l'envoi",
  "customer.schedule_onboarding_emails.dialog.at.helper":
    "L'email sera uniquement envoyé aux membres qui ne l'ont pas déjà reçu.",
  "customer.schedule_onboarding_emails.button": "Emails de bienvenue",
  "customer.schedule_onboarding_emails.success": "%{smart_count} email planifié |||| %{smart_count} emails planifiés",
  "customer.schedule_onboarding_email.label": "Email de bienvenue",
  "customer.schedule_onboarding_email.tooltip": "Envoyer immédiatement",
  "customer.schedule_onboarding_email.dialog.title": "Envoi de l'email de bienvenue à %{name}",
  "customer.schedule_onboarding_email.dialog.content":
    "L'email sera envoyé immédiatement, même si le membre l'a déjà reçu.",
  "customer.schedule_onboarding_email.success": "Email envoyé avec succès.",
  "customer.schedule_onboarding_email.chip.sent": "Envoyé",
  "customer.schedule_onboarding_email.chip.scheduled": "Planifié",
  "customer.bulk_update_members.dialog.title": "Mettre à jour par lot les membres du client",
  "customer.bulk_update_members.button": "Mettre à jour par lot",
  "customer.bulk_update_members.created.success": "%{smart_count} membre créé |||| %{smart_count} membres créés",
  "customer.bulk_update_members.updated.success":
    "%{smart_count} membre mis à jour |||| %{smart_count} membres mis à jour",
  "customer.bulk_update_members.deactivated.success":
    "%{smart_count} membre désactivé |||| %{smart_count} membres désactivés",
  "customer.bulk_update_members.deleted.success":
    "%{smart_count} membre supprimé |||| %{smart_count} membres supprimés",
  "customer.bulk_export_members.button": "Télécharger la liste",
  "staff.edit.title": "Membre Ekipe (%{email})",
  "staff.edit.section.generalInformation.title": "Information relative au membre de l'Ekipe",
  "staff.edit.section.role.title": "Gestion des accès",
  "staff.edit.form.id.label": "Identifiant de compte Ekie",
  "staff.edit.form.email.label": "E-mail",
  "staff.edit.form.signUpAt.label": "Date et heure d'inscription",
  "staff.edit.form.lastLoginAt.label": "Date et heure de dernière connexion",
  "staff.edit.form.assignableRoles.label": "Rôles attribuables",
  "staff.edit.form.locked.label": "Vérouillé ?",
  "staff.list.title": "Ekipe",
  "staff.list.filter.isLocked.label": "Vérouillé ?",
  "staff.list.filter.email.label": "E-mail",
  "staff.list.column.email.title": "E-mail",
  "staff.list.column.roles.title": "Roles",
  "staff.list.column.isLocked.title": "Vérouillé",
  "staff.list.column.isLocked.yes": "Oui",
  "staff.list.column.isLocked.no": "Non",
  lawyer: "Avocat",
  lawyers: "Avocats",
  calendar: "Calendrier",
  dispatch: "Dispatch",
  "dispatch.edit.title": "Modifier la configuration des domaines",
  "dispatch.edit.form.accordion.label": "%{domainName} (%{total})",
  "lawyer.edit.title": "Modification de la fiche avocat (%{firstName} %{lastName})",
  "lawyer.edit.section.contact.title": "Contact",
  "lawyer.edit.section.state.title": "Statut",
  "lawyer.edit.section.lawfirm.title": "Cabinet",
  "lawyer.edit.section.skills.title": "Compétences",
  "lawyer.edit.form.input.helper.public": "Public",
  "lawyer.edit.form.streetLine1.label": "Ligne adresse 1",
  "lawyer.edit.form.streetLine2.label": "Ligne adresse 2",
  "lawyer.edit.form.city.label": "Ville",
  "lawyer.edit.form.zipCode.label": "Code postal",
  "lawyer.edit.form.fullName.label": "Nom et prénom",
  "lawyer.edit.form.firstName.label": "Prénom",
  "lawyer.edit.form.lastName.label": "Nom",
  "lawyer.edit.form.email.label": "E-mail",
  "lawyer.edit.form.phoneLandline.label": "Téléphone fixe",
  "lawyer.edit.form.phoneCellPhone.label": "Téléphone mobile",
  "lawyer.edit.form.status.label": "Statut",
  "lawyer.edit.form.privateNotes.label": "Note",
  "lawyer.edit.form.lawFirm.label": "Nom du cabinet",
  "lawyer.edit.form.law.label": "Domaines de compétence",
  "lawyer.edit.form.isEligibleForAiSuggestionAnswer": "Réponses générées par IA visibles",
  "lawyer.edit.form.oathDate": "Date de serment",
  "lawyer.edit.form.languages": "Langues",
  "lawyer.edit.form.misc": "Divers",
  "lawyer.edit.actions.list.label": "Liste des avocats",
  "lawyer.edit.actions.calendar.label": "Calendrier",
  "lawyer.list.title": "Réseau d'avocats",
  "lawyer.list.filter.firstName.label": "Prénom",
  "lawyer.list.filter.lastName.label": "Nom",
  "lawyer.list.filter.email.label": "E-mail",
  "lawyer.list.filter.law.label": "Domaines de compétence",
  "lawyer.list.filter.status.label": "Statut",
  "lawyer.list.filter.status.active": "Actif",
  "lawyer.list.filter.status.inactive": "Inactif",
  "lawyer.list.filter.status.in_test": "En test",
  "lawyer.list.filter.id.label": "ID",
  "lawyer.list.column.fullName.title": "Nom et prénom",
  "lawyer.list.column.lastName.title": "Nom",
  "lawyer.list.column.firstName.title": "Prénom",
  "lawyer.list.column.email.title": "E-mail",
  "lawyer.list.column.status.title": "Statut",
  "lawyer.list.column.status.active": "Actif",
  "lawyer.list.column.status.inactive": "Inactif",
  "lawyer.list.column.status.in_test": "En test",
  "lawyer.appointment.move.confirmTitle": "Déplacer la consultation de %{title} ?",
  "lawyer.appointment.edit.title": "Consultation avec %{title}",
  "lawyer.appointment.edit.start_at": "Date et heure de début",
  "lawyer.appointment.edit.save": "Déplacer",
  "lawyer.appointment.edit.moveButton.label": "Déplacer",
  "lawyer.availability.delete.confirmTitle": "Supprimer la disponibilité ?",
  "lawyer.calendar.legend.appointment_closed": "Consultation cloturée",
  "lawyer.calendar.legend.appointment_phone": "Consultation téléphonique",
  "lawyer.calendar.legend.appointment_video": "Consultation vidéo",
  "lawyer.calendar.legend.availability": "Créneau de disponibilité",
  "member.label": "Membre |||| Membres",
  "member.title.list": "Membres",
  "member.lastName": "Nom",
  "member.firstName": "Prénom",
  "member.isCseMember": "Appartient au CSE",
  "member.isAdmin": "Peut administrer les comptes du client",
  "member.email": "E-mail",
  "member.fullName": "Nom",
  "member.customer": "Client",
  "member.status": "Abonnement",
  "member.status.active": "Actif",
  "member.status.active_terminated": "Résiliation en cours",
  "member.status.terminated": "Résilié",
  "member.status.new": "Non créé",
  "member.cse_member": "Membre CSE",
  "member.admin": "Administrateur",
  "member.onboarding_email": "E-mail de bienvenue",
  "member.phone": "Téléphone",
  "member.id": "ID",
  "member.section.contact": "Coordonnées",
  "member.secondary_email": "E-mail secondaire",
  "member.phone_cellphone": "Téléphone portable",
  "member.phone_landline": "Téléphone fixe",
  "member.section.subscriptions": "Abonnements",
  "member.product": "Produit",
  "member.aside.id": "Identifiant",
  "member.aside.signup": "Date d'inscription",
  "member.aside.lastLogin": "Date de dernière connexion",
  "member.aside.questionCount": "%{smart_count} question |||| %{smart_count} questions",
  "member.aside.consultationCount": "%{smart_count} consultation |||| %{smart_count} consultations",
  "member.reset_password": "Réinitialiser le mot de passe",
  "member.reset_password.confirmTitle": "Êtes-vous sûr de vouloir réinitialiser le mot de passe ?",
  "member.reset_password.confirmContent": "Un email avec les instructions sera envoyé à l'adresse e-mail principale.",
  "member.reset_password.success": "Email de réinitialisation envoyé avec succès.",
  "member.search": "Rechercher",
  "member.cancel": "Résilier",
  "member.cancel.title": "Résilier l'abonnement de %{name}",
  "member.cancel.cancelLater": "Résilier dans 1 mois",
  "member.cancel.cancelNow": "Résilier immédiatement",
  "member.delete": "Supprimer",
  "member.delete.title": "Supprimer %{name}",
  "member.delete.content": "Ce membre ne s'étant jamais connecté, il sera supprimé définitivement.",
  "member.anonymize": "Anonymiser les données",
  "member.anonymize.title": "Anonymiser les questions et réponses de %{name}",
  "member.anonymize.content":
    "Êtes-vous sur de vouloir anonymiser intégralement les questions et réponses de ce membre ? Cette opération est irréversible.",
  "member.subscribe.dialog.title": "Souscription au produit",
  "member.subscribe.button.label": "Souscrire",
  "common.field.missingValue": "N/A",
  "product.label": "Produit |||| Produits",
  "question.label": "Question |||| Questions",
  "question.menu.label": "Questions",
  "question.menu.assignable": "À assigner",
  "question.menu.unanswered": "En cours",
  "question.menu.answerToValidate": "Réponses à valider",
  "question.menu.answered": "Traitées",
  "question.edit.title": "Question %{questionId}",
  "question.edit.question.title": "Question",
  "question.edit.status.awaiting_payment": "Attente paiement membre",
  "question.edit.status.assigned": "Assignation en attente d'acceptation",
  "question.edit.status.pending_qualification": "Pas d'assignation",
  "question.edit.status.assignment_rejected": "Assignation refusée",
  "question.edit.status.answer_validated": "Réponse publiée",
  "question.edit.status.awaiting_manual_check": "Attente validation manuelle",
  "question.edit.status.assignment_accepted": "Assignation acceptée",
  "question.edit.status.answered": "Question répondue",
  "question.edit.status.additional_request_in_progress": "Demande additionnelle",
  "question.edit.status.additional_request_answered": "Demande additionnelle répondue",
  "question.edit.status.reviewed": "Note sur la réponse donnée",
  "question.edit.status.archived": "Question archivée",
  "question.edit.assignation.status.assignment_accepted": "Acceptée",
  "question.edit.assignation.status.assigned": "En attente",
  "question.edit.assignation.tooltip": "Assignation de type '%{source}'",
  "question.edit.assignation.tooltip_with_reject": "Assignation de type '%{source}', motif de rejet: %{reject_reason}",
  "question.edit.tag": "Tag",
  "question.edit.tag.dialog.title": "Modifier le tag",
  "question.edit.tag.editButton": "Modifier",
  "question.edit.tag.missing": "Aucun",
  "question.edit.tag.badConfiguration": "Le tag ne correspond pas à la configuration du produit",
  "question.edit.law_domain.missing": "Aucun",
  "question.edit.law_domain.label": "Domaine",
  "question.edit.askedAt": "Posée le",
  "question.edit.pendingValidation.label": "Réponse en attente de validation",
  "question.edit.answeredAt": "Répondu le",
  "question.edit.draftedAt": "Saisie le",
  "question.edit.answeredBy": "par",
  "question.edit.remainingTimeToAnswerPrefix": "Délai pour répondre:",
  "question.edit.additionalRequestAt": "Demande complémentaire faite le",
  "question.edit.additionalRequestAnswerAt": "Complément de réponse fait le",
  "question.edit.ratingAt": "Note donnée le",
  "question.edit.validateAnswer.button": "Valider la réponse",
  "question.edit.validateAnswer.dialog.title": "Valider la réponse ?",
  "question.edit.validateAnswer.dialog.content":
    "En confirmant que la réponse de l'avocat est bien valide, elle deviendra visible par le membre.",
  "question.edit.draftValidatedAnswer.button": "Mettre la réponse en brouillon",
  "question.edit.draftValidatedAnswer.dialog.title": "Mettre en brouillon ?",
  "question.edit.draftValidatedAnswer.dialog.content":
    "En confirmant, la réponse passera à l'état de 'brouillon', devenant invisible pour le membre. L'avocat qui l'a rédigée pourra la modifier et la soumettre à nouveau.",
  "question.edit.archive.dialog.title": "Archiver ?",
  "question.edit.archive.button": "Archiver",
  "question.edit.archive.dialog.error.missingTag":
    "Un tag doit être renseigné en cas d'archivage pour mauvais domaine de droit",
  "question.edit.assign.dialog.assign": "Assigner",
  "question.edit.assign.dialog.answeredWarning":
    "En réassignant une question déja répondue. La réponse initiale ne sera plus visible par le membre.",
  "question.edit.assign.dialog.reassign": "Ré-assigner",
  "question.edit.assignation.dialog.title": "Assignations",
  "question.edit.assignation.button.label": "Assignations",
  "question.edit.assignation.column.date.title": "Date assignation",
  "question.edit.assignation.noAssignation": "Pas d'assignations",
  "question.edit.assignation.column.lawyer.title": "Avocat",
  "question.edit.assignation.column.status.title": "Statut",
  "question.edit.answer.column.lawyer.title": "Avocat",
  "question.edit.answer.column.date.title": "Date réponse",
  "question.edit.answer.column.status.title": "Statut",
  "question.edit.answer.noAnswer": "Pas de réponses",
  "question.answer.recordRepresentation": "#%{id} - status %{status}",
  "question.answer.status.published": "Publiée",
  "question.answer.status.pending_moderation": "En attente de vérification",
  "question.answer.status.taken_down": "Dépubliée",
  "question.answer.status.draft": "Brouillon",
  "question.edit.deadline.label": "Date limite de réponse",
  "question.edit.assignedLawyer.label": "Avocat assigné",
  "question.edit.archivedAt.label": "Date d'archivage",
  "question.edit.archivedReason.label": "Motif d'archivage",
  "question.edit.archivedReason.other": "Autre",
  "question.edit.archivedReason.excluded_domain": "Domaine exclu de l'offre",
  "question.edit.archivedReason.no_question_asked": "Pas de requête dans la question",
  "question.edit.archivedReason.too_many_questions_asked": "Plus d'une requête dans la question",
  "question.edit.notify.success": "Question mise à jour",
  "question.edit.aiSuggestionAnswer.dialog.title":
    "Comparaison avec la suggestion de réponse pour la question %{questionId}",
  "question.edit.aiSuggestionAnswer.button.tooltip": "Voir la différence avec la réponse générée par l'IA",
  "question.edit.aiSuggestionAnswer.generatedAt.label": "Générée par IA le",
  "question.edit.aiSuggestionAnswer.link.label": "Suggestion de réponse générée par IA le",
  "question.assignation.status.accepted": "Acceptée",
  "question.assignation.status.rejected": "Rejetée",
  "question.assignation.status.pending": "Attente d'acceptation",
  "question.assignableList.title": "Questions à assigner",
  "question.assignableList.noAssignation": "Aucune assignation",
  "question.unansweredList.title": "Questions en cours",
  "question.answeredList.title": "Questions répondues",
  "question.memberList.title": "Questions",
  "question.answerToValidate.title": "Questions avec réponse à valider",
  "question.list.filter.status.label": "Statut",
  "question.list.filter.law_domain.label": "Domaine",
  "question.list.filter.createdAtFrom.label": "Créé depuis",
  "question.list.filter.createdAtTo.label": "Créé jusqu'à",
  "question.list.filter.memberRating.label": "Note donnée",
  "question.list.filter.timeToAnswer.label": "Temps restant pour répondre",
  "question.list.filter.timeToAnswer.value.moreThan3h": "Plus de 3h",
  "question.list.filter.timeToAnswer.value.lessThan3h": "Moins de 3h",
  "question.list.filter.timeToAnswer.value.lessThan1h": "Moins de 1h",
  "question.list.column.assignedLawyers.title": "Assignations",
  "question.list.column.acceptedAssignedLawyers.title": "Avocats",
  "question.list.column.status.title": "Statut",
  "question.list.column.assignation.status.title": "Statut de l'assignation",
  "question.list.column.deadline.title": "Date limite de réponse",
  "question.list.column.memberRating.title": "Note donnée par le membre",
  "question.list.column.askedDate.title": "Date de la question",
  "question.list.column.remainingTimeToAnswer.title": "Temps restant pour répondre",
  "question.list.column.remainingTimeToAnswer.late": "retard de %{duration}",
  "question.list.column.domain.title": "Domaine",
  "question.list.column.source.title": "Source",
  "question.list.column.member.title": "Membre",
  "question.list.column.customer.title": "Client",
  "question.list.column.customer.b2c": "B2C",
  "resources.staff/products.tab.general.label": "Info générales",
  "resources.staff/products.tab.questions.label": "Questions",
  "resources.staff/products.tab.consultations.label": "Consultations",
  "resources.staff/products.tab.onboarding.label": "Onboarding",
  "resources.staff/products.fields.name": "Nom",
  "resources.staff/products.fields.collaborator_type": "Type de collaborateur",
  "resources.staff/products.fields.max_total_questions_per_member": "Max de questions par membre",
  "resources.staff/products.fields.max_total_consultations_per_member": "Max de consultations par membre",
  "resources.staff/products.fields.onboarding_email_white_list":
    "Liste d'email rattachés automatiquement au produit à la création du membre",
  "resources.staff/products.fields.onboarding_email_white_list.helper":
    "La liste d'email au niveau du produit ne doit plus être utilisée. Utilisez la fonctionnalité 'collaborateur' à la place.",
  "resources.staff/products.fields.max_parallel_questions_per_member": "Max de questions simultanées par membre",
  "resources.staff/products.fields.onboarding_code": "Code d'inscription",
  "resources.staff/products.fields.onboarding_domains":
    "Domaine de mails rattachés automatiquement au produit à la création du membre",
  "resources.staff/products.fields.onboarding_domains.errorMessage":
    "Vous devez entrer domaine d'email valide (tout ce qui suit le @ dans l'adresse email)",
  "resources.staff/products.fields.forbidden_question_tag_ids": "Tags exclus",
  "resources.staff/products.fields.restricted_question_tag_ids": "Uniques tags autorisés",
  "subscription.product": "Produit",
  "subscription.start_date": "Du",
  "subscription.end_date": "Au",
  "subscription.status": "Statut",
  "subscription.edit.title": "Modifier l'abonnement",
  "subscription.cancel": "Résilier",
  "subscription.cancel.confirmTitle": "Êtes-vous sûr de vouloir résilier cet abonnement ?",
  "subscription.cancel.confirmContent": "La résiliation prendra effet dans 1 mois.",
};
